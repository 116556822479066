import React from 'react'
import styled from 'styled-components'

const BackGround = styled.div`
  width: 100%;
  z-index: 90;
  display: flex;

  align-items: center;
  min-height: 700px;
  padding: 60px 200px;
  margin: none;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-color: #2b7cff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%232e74ee' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23316cdd' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%233564cc' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23385cbb' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%233b54aa' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`
const Backer = props => {
  return <BackGround>{props.back}</BackGround>
}

export default Backer
