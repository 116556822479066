import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Icon } from 'semantic-ui-react'

const Menu = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: 50px;
  display: flex;
  .login {
    position: absolute;
    display: inline-flex;
    right: 30px;
    padding: 1px 2px;
    border: 1px solid white;
    color: white;
  }
`
const styles = {
  list: {
    width: 250,
    background: 'white',
  },
  fullList: {
    width: 'auto',
    background: 'white',
  },
  word: {
    color: 'black',
    fontSize: '18px',
  },
}

const DATA = [
  {
    text: 'Home',
    path: '/',
  },
  {
    text: 'Common Questions',
    path: '/faq',
  },
  {
    text: 'Sign Up',
    path: '/signup',
  },
]
class TemporaryDrawer extends React.Component {
  state = {
    top: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props

    const fullList = (
      <div className={classes.fullList}>
        <List>
          {DATA.map((item, index) => (
            <Link to={item.path}>
              <ListItem button key={item.text}>
                <ListItemIcon />
                <ListItemText primary={item.text} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          {[
            <Link to="/services/voicebroadcast">Voice Broadcasting</Link>,
            <Link to="/services/dialer">Predictive Dialer</Link>,
            <Link to="/services/ringless">Ringless Voicemail</Link>,
            <Link to="/services/sms">Sms</Link>,
          ].map((text, index) => (
            <ListItem button key={index}>
              <ListItemText className={classes.word} primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    )

    return (
      <Menu>
        <Icon
          style={{
            paddingLeft: '15px',
            paddingTop: '10px',
            marginTop: '5px',
          }}
          name="content"
          size="large"
          onClick={this.toggleDrawer('top', true)}
        />

        <Drawer
          anchor="top"
          open={this.state.top}
          onClose={this.toggleDrawer('top', false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('top', false)}
            onKeyDown={this.toggleDrawer('top', false)}
          >
            {fullList}
          </div>
        </Drawer>

        <a
          style={{
            display: 'block',
            marginTop: '15px',

            fontSize: '21px',
            marginLeft: '35px',
            color: 'rgba(20,110,240,1)',
          }}
          href="tel:+1-866-403-5986"
        >
          (866)403-5986
        </a>

        <Button
          className="login"
          href="https://app.universaldialers.com"
          style={{ padding: '6px 12px', marginTop: '8px' }}
        >
          Log in
        </Button>
      </Menu>
    )
  }
}

export default withStyles(styles)(TemporaryDrawer)
