import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: 'Kanit', sans-serif;
  text-align: center;
  color: rgba(20, 120, 220, 1);
  font-size: 20px;
`

const StyledInput = styled.input`
  border: none;
  border: 1px solid rgba(20, 20, 20, 0.3);
  border-radius: 5px;
  padding: 8px 8px;
  width: 100%;
  &:focus {
    outline: none;
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class FormDialog extends React.Component {
  state = {
    open: false,
    name: '',
    email: '',
    phone: '',
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => {
        this.setState({ open: false })
      })
      .then(() =>
        alert(
          'Thank You for Submitting! A Customer Service Representative Will Contact You During Business Hours.',
        ),
      )

      .catch(error => alert(error))
    e.preventDefault()
  }
  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, email, phone } = this.state
    return (
      <>
        <Button
          fullWidth
          className="button"
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          {this.props.buttonTitle}
          {this.props.icon}
        </Button>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          {' '}
          <DialogContent>
            {' '}
            <Title>Start Sending With Universal Dialers!</Title>
            <DialogContentText>
              Reach thousands of customers in minutes with our 100% cloud based
              system. Contact your lists from anywhere with 99.9% uptime!
            </DialogContentText>
            <form onSubmit={this.handleSubmit}>
              <input type="hidden" name="form-name" value="contact" />
              <p style={{ marginTop: '35px' }}>
                <b>NAME</b>
              </p>
              <StyledInput
                style={{ marginTop: '0px' }}
                autoFocus
                name="name"
                onChange={this.handleChange}
                value={name}
                placeholder="Your Name Here"
                label="Name"
                type="text"
                required
              />
              <p style={{ marginTop: '35px' }}>
                <b>PHONE</b>
              </p>
              <StyledInput
                style={{ marginTop: '0px' }}
                value={phone}
                name="phone"
                placeholder="(555)555-5555"
                onChange={this.handleChange}
                label="Phone Number"
                type="tel"
                required
              />
              <p style={{ marginTop: '35px' }}>
                <b>EMAIL</b>
              </p>
              <StyledInput
                style={{ marginTop: '0px' }}
                value={email}
                name="email"
                placeholder="you@email.com"
                onChange={this.handleChange}
                label="Email Address"
                type="email"
                required
              />{' '}
              <Button
                style={{
                  marginTop: '40px',
                  padding: '5px 10px',
                  color: 'white',
                  fontWeight: '800',
                  fontSize: '16px',
                  border: 'none',
                  background: 'rgba(10,100,240,1)',
                  float: 'right',
                }}
                type="submit"
                color="primary"
              >
                Submit
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default FormDialog
