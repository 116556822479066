import React from 'react'
import pricing from './pricing.png'
import PropTypes from 'prop-types'
import Footer from './Footer'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Questions from './Questions'
import FormDialog from './PopForm'
import Backer from './Backer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: '100%',
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    textAlign: 'center',
    maxWidth: 600,
    margin: '0 auto',
    padding: '30px',
  },
  cardHeader: {
    backgroundColor: '#64b5f6',
    color: 'white',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit * 5,
    },
  },
  Button: {
    padding: theme.spacing.unit * 0,
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
})

const tiers = [
  {
    title: 'Universal Dialers',

    subTitle:
      '*Monthly subscription fees are nonrefundable upon cancellation, however you may unsubscribe at any time, no questions asked! Successful voice drops and/or broadcasts are nunrefundable as well',
    description: [
      'All-In-One Platform – 100% cloud based',
      'Unlimited Campaigns',
      'Unlimited Audio Recordings',
      'Mobile/Landline Scrubbing',
      'Advanced Scheduling',
      'Only Pay for Successful RVM drops',
      'Tracking & Advanced Reporting',
      'No Hardware Required',
      'User Friendly Interface',
      'Start Sending in 3 Quick Steps',
      'Reach Thousands of Prospects in Minutes',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
]

function Pricing(props) {
  const { classes } = props

  return (
    <React.Fragment>
      <CssBaseline />

      <Toolbar />

      <main className={classes.layout}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <img
            src={pricing}
            alt="pricing"
            style={{ width: '310px', textAlign: 'center' }}
          />
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            <b>
              Sign up with absolutly no commitment &amp; see what today's most
              powerful lead generation platform brings to your business
            </b>
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            Simply click the Sign Up button below, fill out the 30 second form
            and submit. Following your submission, you will be contacted to
            further personalize your account.
          </Typography>
        </div>
        {/* End hero unit */}
        <Backer
          back={
            <Grid container spacing={40} alignItems="flex-end">
              {tiers.map(tier => (
                // Enterprise card is full width at sm breakpoint
                <Grid item key={tier.title} xs={12}>
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        />
                      </div>
                      {tier.description.map(line => (
                        <Typography
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      <FormDialog buttonTitle="Click Here to Get Started Today!" />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          }
        />
      </main>

      <Questions />
      <Footer />
    </React.Fragment>
  )
}

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Pricing)
